import { slugify } from '#vuepal/helpers/url'

export function removeWhitespace(value = ''): string {
  return value.replace(/ /g, '')
}

export function modulo(n: number, m: number) {
  return ((n % m) + m) % m
}

export function notEmpty<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  return value !== null && value !== undefined
}

export function onlyUnique<T>(value: T, index: number, self: Array<T>) {
  return self.indexOf(value) === index
}

export function decodeEntities(encodedString: string) {
  const RGX = /&(nbsp|amp|quot|lt|gt);/g
  const translate: Record<string, string> = {
    nbsp: ' ',
    amp: '&',
    quot: '"',
    lt: '<',
    gt: '>',
  }
  return encodedString
    .replace(RGX, function (_match, entity) {
      return translate[entity]
    })
    .replace(/&#(\d+);/gi, function (_match, numStr) {
      const num = parseInt(numStr, 10)
      return String.fromCharCode(num)
    })
}

/**
 * Basic removal of HTML tags from a string.
 *
 * Note that this is NOT bulletproof and should only be used when it's okay if removal fails.
 */
export function stripTags(v: string): string {
  return v.replace(/(<([^>]+)>)/gi, '').replaceAll('&nbsp;', ' ')
}

/**
 * Check if the string starts with a number.
 */
export function startsWithNumber(v = ''): boolean {
  return /^\d/.test(v)
}

/**
 * Generate a valid HTML ID attribute value for a string.
 *
 * @see https://www.w3.org/TR/html4/types.html#type-id
 */
export function toValidHtmlId(text: string): string {
  let id = slugify(text)
  if (startsWithNumber(id)) {
    id = 's-' + id
  }

  return id
}
